import React, { useEffect, useState } from "react";


export default function WhySoplex() {
  useEffect(() => {
    // Set canonical link dynamically
    const canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', 'https://soplextechnologies.com/about');
    document.head.appendChild(canonicalLink);

    // Set title dynamically
    document.title = 'Why Choose Soplex Technologies - Smart Solutions for Your Business';

    // Set meta description dynamically
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Discover why Soplex Technologies is the trusted partner for smart business solutions. Our expertise, innovation, and commitment to excellence set us apart in the industry.';
    document.head.appendChild(metaDescription);

    // Clean up on unmount (for single page applications)
    return () => {
      document.head.removeChild(canonicalLink);
      document.head.removeChild(metaDescription);
    };
  }, []);

  
    const [points, setPoints] = useState([]);

    useEffect(() => {
        fetch('/points.json') // path to the services.json file
          .then((response) => response.json())
          .then((data) => setPoints(data))
          .catch((error) => console.error('Error fetching the services:', error));
      }, []);
  return (
    <div>

      {/* <!--Why Choose US--> */}
    <section className="why-choose-us">
        <h1>Why Choose Soplex Technologies</h1>
        <p style={{marginBottom: '50px'}}>In today’s digital landscape, you need more than a service provider—you need a trusted technology partner. At Soplex Technologies, we deliver innovative solutions that drive real results. Here's why we're the right choice:
        </p>
        <div className="cards-container">
      {points.map((point) => (
        <div className="card" key={point.id}>
          <div className="icon" dangerouslySetInnerHTML={{ __html: point.icon }}></div>
          <h2>{point.title}</h2>
          <p>{point.description}</p>
        </div>
      ))}
    </div>
        <div className="shapes">
            <div className="circle"></div>
            <div className="triangle"></div>
        </div>
    </section>
    </div>
  )
}
