import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import Navbar from "./Components/Navbar";
import Carousel from "./Components/Carousel";
import Header from "./Components/Header";
import Home from "./Components/Home";
import About from "./Components/About";
import WhySoplex from "./Components/WhySoplex";
import Services from "./Components/Services";
import Team from "./Components/Team";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import BlogPage from "./Components/BlogPage";
import ScrollToTop from "./Components/ScrollToTop";
import Blog1 from "./Components/Blog1";
import Blog2 from "./Components/Blog2";
import Blog3 from "./Components/Blog3";
import Blog4 from "./Components/Blog4";
import Spinner from "./Components/Spinner";


function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading for 2 seconds (or add actual loading logic)
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000); // adjust time as needed
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Spinner />; // Display the spinner when loading
  }
  return (
    <Router>
      <ScrollToTop/>
      <Navbar/>
      <Routes>
      <Route path="/blogs" element={<><Header title="Blogs" page="Blogs" /><BlogPage /></>} />
      <Route path="/blog1" element={<><Header title="Blogs" page="Blog1" /><Blog1 /></>} />
      <Route path="/blog2" element={<><Header title="Blogs" page="Blog2" /><Blog2 /></>} />
      <Route path="/blog3" element={<><Header title="Blogs" page="Blog3" /><Blog3 /></>} />
      <Route path="/blog4" element={<><Header title="Blogs" page="Blog4" /><Blog4 /></>} />
        <Route path="/contact" element={<><Header title="Contact Us" page="Contact" /><Contact /></>} />
        <Route path="/team" element={<><Header title="Our Team" page="Team" /><Team /></>} />
        <Route path="/services" element={<><Header title="Services" page="Services" /><Services /></>} />
        <Route path="/why-soplex" element={<><Header title="Why Choose Soplex Technologies?" page="Why-Soplex" /><WhySoplex /></>} />
        <Route path="/about" element={<><Header title="About Us" page="About" /><About /></>} />
        <Route path="/index.html" element={<><Carousel /><Home /></>} />
        <Route path="/" element={<><Carousel /><Home /></>} />
      </Routes>
    <Footer/>
    </Router>
  );
}

export default App;