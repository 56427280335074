import React, { useState, useEffect } from "react";

const Blog2 = () => {
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    fetch('Blogs/blog2.json')
      .then((response) => response.json())
      .then((data) => setBlogData(data))
      .catch((error) => console.error('Error fetching blog data:', error));
  }, []);

  if (!blogData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-container">
      <h1>{blogData.title}</h1>
      <p>{blogData.introduction.text}</p>

      {blogData.sections.map((section, index) => (
        <div className="section" key={index}>
          <h2>{section.title}</h2>
          {section.content && section.content.map((contentItem, contentIndex) => (
            <p key={contentIndex}>{contentItem}</p>
          ))}
          {section.subsections &&
            section.subsections.map((subsection, subIndex) => (
              <div className="subsection" key={subIndex}>
                <h3>{subsection.subtitle}</h3>
                {subsection.content &&
                  subsection.content.map((subContentItem, subContentIndex) => (
                    <p key={subContentIndex}>{subContentItem}</p>
                  ))}
              </div>
            ))}
        </div>
      ))}

      {blogData.conclusion && (
        <div className="conclusion">
          <h2>Conclusion</h2>
          {blogData.conclusion.content.map((conclusionItem, conclusionIndex) => (
            <p key={conclusionIndex}>{conclusionItem}</p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blog2;
