import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const BlogPage = () => {
  useEffect(() => {
    // Set canonical link dynamically
    const canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', 'https://soplextechnologies.com/blogs');
    document.head.appendChild(canonicalLink);

    // Set title dynamically
    document.title = 'Blog - Insights and Tips | Soplex Technologies';

    // Set meta description dynamically
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = "Explore the latest insights, tips, and trends in technology and business from the experts at Soplex Technologies. Stay informed with our regular blog updates.";
    document.head.appendChild(metaDescription);

    // Clean up on unmount (for single page applications)
    return () => {
      document.head.removeChild(canonicalLink);
      document.head.removeChild(metaDescription);
    };
  }, []);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetching the summary data from the JSON file
    fetch("/Blogs/blog-summary.json")
      .then((response) => response.json())
      .then((data) => setBlogs(data)) // Setting the fetched array directly
      .catch((error) => console.error("Error fetching the blogs:", error));
  }, []);

  return (
    <>
      <div className="blog-page">
        <h1>Blog List</h1>
        <div className="blog-list">
          {blogs.map((blog, index) => (
            <div key={index} className="blog-item">
              <h2>{blog.title}</h2>
              <p>{blog.description}</p>
              {/* Link to the blog details page */}
              <Link to={`/blog${index + 1}`} className="read-more">
                Read More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogPage;
