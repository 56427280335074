import React, { useState, useEffect } from "react";

const Blog1 = () => {
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    fetch('Blogs/blog1.json')
      .then((response) => response.json())
      .then((data) => setBlogData(data))
      .catch((error) => console.error('Error fetching blog data:', error));
  }, []);

  if (!blogData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-container">
      <h1>{blogData.title}</h1>
      <p>{blogData.introduction.text}</p>

      {blogData.sections.map((section, index) => (
        <div className="section" key={index}>
          <h2>{section.title}</h2>
          {section.tools ? (
            section.tools.map((tool, toolIndex) => (
              <div key={toolIndex}>
                <h3>{tool.name}</h3>
                <p>{tool.description}</p>
                <ul>
                  {tool.key_features.map((feature, featureIndex) => (
                    <li key={featureIndex}>{feature}</li>
                  ))}
                </ul>
                <p><strong>Usage:</strong> {tool.usage}</p>
              </div>
            ))
          ) : (
            section.subsections && section.subsections.map((subsection, subIndex) => (
              <div key={subIndex}>
                <h4>{subsection.subtitle}</h4>
                <p>{subsection.content.join(' ')}</p>
              </div>
            ))
          )}
        </div>
      ))}

      <p>{blogData.conclusion.text}</p>
    </div>
  );
};

export default Blog1;
