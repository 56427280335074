import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';



export default function About() {
    useEffect(() => {
        // Set canonical link dynamically
        const canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', 'https://soplextechnologies.com/about');
        document.head.appendChild(canonicalLink);
    
        // Set title dynamically
        document.title = 'About Us - Soplex Technologies | Smart Solutions for a Smarter Business';
    
        // Set meta description dynamically
        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = 'Let Soplex Technologies help you shape the future of your business with our global software solutions for optimal innovation.';
        document.head.appendChild(metaDescription);
    
        // Add structured data dynamically
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.textContent = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "AboutPage",
            "url": "https://soplextechnologies.com/about",
            "mainEntity": {
                "@type": "Organization",
                "name": "Soplex Technologies",
                "logo": "https://soplextechnologies.com/logo.png",
                "sameAs": [
                    "https://facebook.com/soplextechnologies",
                    "https://twitter.com/soplextech"
                ]
            }
        });
        document.head.appendChild(script);
    
        // Clean up on unmount (for single page applications)
        return () => {
            document.head.removeChild(canonicalLink);
            document.head.removeChild(metaDescription);
            document.head.removeChild(script);
        };
    }, []);    
  return (
    <div>

      {/* <!-- About Start --> */}
    <div class="container-fluid py-5 my-5 mb-0">
        <div class="parallax parallax-left"></div>
        <div class="parallax parallax-right"></div>
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                    <div class="h-100 position-relative" style={{height: '100px'}}>
                        <img src="img/who-we-are.jpg" class="img-fluid w-90 rounded" alt="Who We Are - Soplex Technologies Team" loading="lazy"/>
                    </div>
                </div>
                <div class="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                    <h5 class="text-primary">About Us</h5>
                    <h1 class="mb-4">Who We Are</h1>
                    <p>As the founder and CEO of Soplex Technologies, <b>Muhammad Sajjad Ullah </b>is the guiding force behind the company’s vision and strategic direction.
                    With a deep understanding of the digital landscape, he leads the company towards continuous innovation, ensuring we deliver cutting-edge solutions that address the evolving challenges of businesses.
                    His expertise and forward-thinking approach have positioned Soplex Technologies as a leader in the industry, consistently driving growth and fostering digital transformation for our clients.</p>
                    <Link to="/team" class="btn btn-secondary rounded-pill px-5 py-3 text-white">More Details</Link>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}
    {/* <!-- Mission Start --> */}
    <section>
        <div class="container-xxl py-5 mt-0 mb-0" id="about">
            <div class="parallax1 parallax1-left"></div>
            <div class="parallax1 parallax1-right"></div>
            <div class="container py-5 px-lg-5">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-6 col-md-12 wow fadeInUp intro" data-wow-delay="0.3s">
                        <h1 class="mb-4 text-center">Shaping the Future with Innovation</h1>
                        <p class="mb-4 about">At Soplex Technologies, we specialize in providing innovative AI-driven solutions and custom software development services to businesses worldwide. With a passion for technology and a customer-first approach, our mission is to help organizations leverage the power of artificial intelligence to transform their operations, enhance customer experiences, and drive growth. With a focus on healthcare, finance, e-commerce, along with several others, our business digital tools aim to empower our clients to stay ahead in a rapidly changing digital landscape.
                        </p>
                    </div><br/>
                    <div class="col-lg-6 col-md-12 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <img class="img-fluid target" src="img/target-01-Photoroom.png" alt="Shaping the Future with AI and Innovation"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Mission End --> */}
    {/* <!-- Vision Start --> */}
    <section>
        <div class="container-xxl py-5 mt-0" id="about">

            <div class="parallax1 parallax1-right"></div>
            <div class="container py-5 px-lg-5">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-6 col-md-12 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <img class="img-fluid target" src="img/our-vision-Photoroom.png" alt="Our Vision - Leading in AI Innovation"/>
                    </div>
                    <div class="col-lg-6 col-md-12 wow fadeInUp intro" data-wow-delay="0.3s">
                        <h1 class="mb-4"> Our Vision</h1>
                        <p class="mb-4 about">To lead in AI innovation, empowering businesses to adapt, innovate, and grow through transformative software solutions.
                        </p>
                    </div><br/>

                </div>
            </div>
        </div>
    </section>
    {/* <!-- Vision End --> */}
    {/* <!-- Our Commitment to You --> */}
{/* Our Commitment to You */}
<section className="commitment-section">
    <div className="container-xxl py-5 mt-0 mb-0">
        <div className="container py-5 px-lg-5">
            <div className="row g-5 align-items-center">
                <div className="col-12 wow fadeInUp intro" data-wow-delay="0.3s">
                    <h1 className="mb-4 text-center">Our Commitment to You</h1>
                    <p className="mb-4 about text-center">
                        At Soplex Technologies, our goal is to build long-lasting relationships that are rooted in trust and mutual growth. We’re more than just a service provider—we’re your dedicated partner on the journey to digital transformation.
                    </p>
                    <p className="text-center">
                        <strong><br />Together, we’ll shape the future. Connect with AI experts today!</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
{/* Our Commitment to You End */}



{/* <!-- Our Commitment to You End --> */}

    </div>
  )
}
