import React, { useEffect } from "react";
import { Link } from 'react-router-dom'

export default function Home() {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Soplex Technologies",
            "url": "https://soplextechnologies.com/",
            "logo": "https://soplextechnologies.com/img/logo.webp",
            "description": "From healthcare automation tools to fraud detection in finance, Soplex Technologies is empowering global businesses with top-notch AI solutions.",
            "sameAs": [
                "https://www.facebook.com/profile.php?id=61566326070506",
                "https://www.linkedin.com/company/soplex-technologies"
            ]
        });
        document.head.appendChild(script);
        return () => document.head.removeChild(script);
    }, []);
    
    useEffect(() => {
        
        // Set canonical link dynamically
        const canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', 'https://soplextechnologies.com/');
        document.head.appendChild(canonicalLink);
        const metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = 'AI solutions, software development, Soplex Technologies, healthcare automation, fraud detection, web development';
        document.head.appendChild(metaKeywords);

        const ogMeta = document.createElement('meta');
        ogMeta.setAttribute('property', 'og:title');
        ogMeta.content = 'Latest Blog & News from Soplex Technologies';
        document.head.appendChild(ogMeta);


    
        // Set title dynamically
        document.title = 'Soplex Technologies | AI Solutions & Custom Software for Businesses';
    
        // Set meta description dynamically
        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = "From healthcare automation tools to fraud detection in finance, Soplex Technologies is empowering global businesses with top-notch AI solutions.";
        document.head.appendChild(metaDescription);
    
        // Clean up on unmount (for single page applications)
        return () => {
          document.head.removeChild(canonicalLink);
          document.head.removeChild(metaDescription);
        };
      }, []);
      function showText(coreValue) { 
        const textMapping = {
            innovation: "We thrive on innovation, continuously <br>  exploring new technologies and <br>   methodologies to enhance our <br>   service offerings.",
            collaboration: "Our team collaborates closely with clients <br> to understand their challenges, goals, and opportunities, ensuring that every <br>  project is a success.",
            excellence: "We are committed to excellence in <br>  everything we do, from project execution <br>  to customer service."
        };

        document.getElementById('main-text').innerHTML = textMapping[coreValue];
    }
  return (
    <div>
      {/* <!-- Intro Start --> */}
    <div className="container-fluid about pb-5 mt-5">
        <div className="parallax parallax-left"></div>
        <div className="parallax parallax-right"></div>
        <div className="container pb-5" id="intro">
            <div className="row g-5">
                {/* <!-- About Section --> */}
                <div className="col-xl-5 d-flex align-items-center wow fadeInLeft" data-wow-delay="0.2s">
                    <div>
                        <h4 className="text-primary">About Us</h4>
                        <h1 className="display-5 mb-4" style={{textAlign: 'left'}}>About Soplex Technologies</h1>
                        <p className="mb-4" style={{textAlign: 'justify'}}>
                        At Soplex Technologies, we believe the future of business is built on innovation. Since our founding, we have been dedicated to helping businesses navigate the ever-changing digital landscape with cutting-edge AI and software development solutions.</p>
                            <p className="mb-4" style={{textAlign: 'justify'}}>Our mission is simple: empower businesses with localized software solutions, tools, and AI technology so that you have the expertise to succeed, evolve, and drive impact.</p>
                            <p className="mb-4" style={{textAlign: 'justify'}}>Transform your business with our digital transformation solutions today!</p>
                    </div>
                </div>
                <div className="col-xl-7 d-flex align-items-center wow fadeInUp" data-wow-delay="0.3s">
                    <div className="image-grid">
                        <div className="column">
                            <Link to="../About/about.html">
                                <img src="img/grid1.webp" alt="Digital Transformation" className="medium" loading="lazy"/>
                            </Link>
                            <Link to="#">
                                <img src="img/grid2.jpg" alt="Artificial Intelligence" className="small" loading="lazy"/>
                            </Link>
                        </div>
                        <div className="column">
                            <Link to="#" style={{marginTop: '10px'}}>
                                <img src="img/grid3.png" alt="Client Centric Approach" className="large" loading="lazy"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Intro End --> */}
    {/* <!-- Why Choose Us Start --> */}
<div className="container-xxl py-5" id="feature">
    {/* <!-- Parallax Backgrounds --> */}
    <div className="parallax1 parallax1-left"></div>
    <div className="parallax1 parallax1-right"></div>
    <div className="container py-5 px-lg-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="mb-5">Why Soplex Technologies?</h1>
        </div>

        <div className="row g-5">
            <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="feature-item bg-light rounded p-4 text-center">
                    <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                        style={{width: '80px', height: '80px'}}>
                        <i className="fa fa-users text-white fs-1" style={{fontSize: '2rem'}}></i>
                    </div>
                    <h5 className="mb-3">Expertise in AI Innovation</h5>
                    <p className="m-0" style={{textAlign: 'center'}}>From machine learning to predictive analytics, natural language processing (NLP) to computer vision, our AI services offer customized solutions that fit your business needs</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="feature-item bg-light rounded p-4 text-center">
                    <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                        style={{width: '80px', height: '80px'}}>
                        <i className="fa fa-chart-bar text-white fs-1" style={{fontSize: '2rem'}}></i>
                    </div>
                    <h5 className="mb-3">Comprehensive Software Solutions</h5>
                    <p className="m-0" style={{textAlign: 'center'}}>We specialize in creating scalable web and mobile applications, building secure enterprise solutions, and delivering SaaS platforms that streamline business processes and enhance user experience.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="feature-item bg-light rounded p-4 text-center">
                    <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                        style={{width: '80px', height: '80px'}}>
                        <i className="fa fa-puzzle-piece text-white fs-1" style={{fontSize: '2rem'}}></i>
                    </div>
                    <h5 className="mb-3">A Global Reach with a Local Touch</h5>
                    <p className="m-0" style={{textAlign: 'center'}}>While we proudly serve clients in the UK, USA, Canada, Pakistan, Australia, and Germany. Our solutions are tailored to meet the unique challenges of businesses.</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="feature-item bg-light rounded p-4 text-center">
                    <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                        style={{width: '80px', height: '80px'}}>
                        <i className="fa fa-puzzle-piece text-white fs-1" style={{fontSize: '2rem'}}></i>
                    </div>
                    <h5 className="mb-3">Building Long-Term Relationships</h5>
                    <p className="m-0" style={{textAlign: 'center'}}>We believe that our job isn’t just to deliver a product—it’s to ensure you succeed in the long run. Our approach is based on collaboration, transparency, and trust.</p>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Why Choose Us End --> */}



    <br/><br/><br/>
            <div className="core-values-wrapper">
                <div className="core-values-section">
                    <div className="main-circle wow bounceIn" data-wow-delay=".3s">
                        <p id="main-text">Our Team's Core Values</p>
                    </div>

                    <div className="small-circle wow fadeInUp" data-wow-delay=".5s" id="innovation" onMouseOver={() => showText('innovation')}>
                        <i className="fas fa-lightbulb"></i>
                        <p>Innovation</p>
                    </div>

                    <div className="small-circle wow fadeInUp" data-wow-delay=".7s" id="collaboration" onMouseOver={() => showText('collaboration')}>
                        <i className="fas fa-handshake"></i>
                        <p>Collaboration</p>
                    </div>

                    <div className="small-circle wow fadeInUp" data-wow-delay=".9s" id="excellence" onMouseOver={() => showText('excellence')}>
                        <i className="fas fa-award"></i>
                        <p>Excellence</p>
                    </div>
                </div>

                <div className="core-values-text wow fadeIn" data-wow-delay="1s">
                    <h2>Why Our Core Values Matter</h2>
                    <p>We believe that these core values shape the way we operate, innovate, and collaborate with clients. By focusing on innovation, collaboration, and excellence, we deliver top-notch solutions that cater to the unique needs of our clients.</p>
                    <p>Hover over each value to learn more about how we embody them in every project.</p>
                </div>
            </div>

{/* <!-- Blog Start --> */}
<div className="container-fluid blogSection py-5 mb-1">
        {/* <!-- Parallax Backgrounds --> */}
<div className="parallax parallax-left"></div>
<div className="parallax parallax-right"></div>
    <div className="container">
        <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px'}}>
            <h5 className="text-primary">Blog</h5>
            <h1>Latest Blog & News</h1>
        </div>
        <div className="row g-5 justify-content-center">
            {/* <!-- Blog Item 1 --> */}
            <div className="col-lg-3 col-xl-4 wow fadeIn" data-wow-delay=".3s" style={{width: '450px'}}>
                <div className="blogSection-item position-relative bg-light rounded">
                    <img src="img/blog1.webp" className="img-fluid w-100 rounded-top" alt="" style={{height: '270px'}}/>
                    <div className="blogSection-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                        <div className="blogSection-icon btn btn-secondary px-3 rounded-pill my-auto">
                            <Link to="/blogs" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blogSection-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                            <div className="blogSection-icon-1">
                                <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                            </div>
                            <div className="blogSection-icon-2">
                            <Link to="https://www.facebook.com/profile.php?id=61566326070506&mibextid=ZbWKwL" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></Link>
                                <Link to="https://www.linkedin.com/company/soplex-technologies/" className="btn me-1"><i className="fab fa-linkedin text-white"></i></Link>
                                <Link to="https://www.instagram.com/soplex_technology?igsh=dWFsbW04bm5yYjJ2" className="btn me-1"><i className="fab fa-instagram text-white"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="blog-content text-center position-relative px-3" style={{marginTop: '25px', height: '130px'}}>
                        <p className="py-2">In today’s fast-paced, digitally-driven world, small businesses face an ongoing challenge: how to remain competitive while simultaneously keeping costs manageable.</p>
                    </div>
                    <div className="blog-coment d-flex justify-content-between px-4 py-3 border bg-primary rounded-bottom"></div>
                </div>
            </div>
            {/* <!-- Blog Item 2 --> */}
            <div className="col-lg-3 col-xl-4 wow fadeIn" data-wow-delay=".3s" style={{width: '450px'}}>
                <div className="blogSection-item position-relative bg-light rounded">
                    <img src="img/blog2.webp" className="img-fluid w-100 rounded-top" alt="" style={{height: '270px'}}/>
                    <div className="blogSection-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                        <div className="blogSection-icon btn btn-secondary px-3 rounded-pill my-auto">
                            <Link to="/blogs" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blogSection-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                            <div className="blogSection-icon-1">
                                <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                            </div>
                            <div className="blogSection-icon-2">
                            <Link to="https://www.facebook.com/profile.php?id=61566326070506&mibextid=ZbWKwL" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></Link>
                                <Link to="https://www.linkedin.com/company/soplex-technologies/" className="btn me-1"><i className="fab fa-linkedin text-white"></i></Link>
                                <Link to="https://www.instagram.com/soplex_technology?igsh=dWFsbW04bm5yYjJ2" className="btn me-1"><i className="fab fa-instagram text-white"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="blog-content text-center position-relative px-3" style={{marginTop: '25px', height:'130px'}}>
                        <p className="py-2">At their core, AI (Artificial Intelligence) and cloud computing are groundbreaking technologies that are reshaping how businesses operate.</p>
                    </div>
                    <div className="blog-coment d-flex justify-content-between px-4 py-3 border bg-primary rounded-bottom"></div>
                </div>
            </div>
            {/* <!-- Blog Item 3 --> */}
            <div className="col-lg-3 col-xl-4 wow fadeIn" data-wow-delay=".3s" style={{width: '450px'}}>
                <div className="blogSection-item position-relative bg-light rounded">
                    <img src="img/blog3.webp" className="img-fluid w-100 rounded-top" alt="" style={{height: '270px'}}/>
                    <div className="blogSection-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                        <div className="blogSection-icon btn btn-secondary px-3 rounded-pill my-auto">
                            <Link to="/blogs" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blogSection-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                            <div className="blogSection-icon-1">
                                <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                            </div>
                            <div className="blogSection-icon-2">
                                <Link to="https://www.facebook.com/profile.php?id=61566326070506&mibextid=ZbWKwL" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></Link>
                                <Link to="https://www.linkedin.com/company/soplex-technologies/" className="btn me-1"><i className="fab fa-linkedin text-white"></i></Link>
                                <Link to="https://www.instagram.com/soplex_technology?igsh=dWFsbW04bm5yYjJ2" className="btn me-1"><i className="fab fa-instagram text-white"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="blog-content text-center position-relative px-3" style={{marginTop: '25px', height: '130px'}}>
                        <p className="py-2">Cybersecurity for SMEs refers to the practices, technologies, and processes that protect a business’s data, systems, and networks from cyber threats.</p>
                    </div>
                    <div className="blog-coment d-flex justify-content-between px-4 py-3 border bg-primary rounded-bottom"></div>
                </div>
            </div>
            {/* <!-- Blog Item 4 --> */}
            <div className="col-lg-3 col-xl-4 wow fadeIn" data-wow-delay=".3s" style={{width: '450px'}}>
                <div className="blogSection-item position-relative bg-light rounded">
                    <img src="img/blog4.webp" className="img-fluid w-100 rounded-top" alt="" style={{height: '270px'}}/>
                    <div className="blogSection-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                        <div className="blogSection-icon btn btn-secondary px-3 rounded-pill my-auto">
                            <Link to="/blogs" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blogSection-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                            <div className="blogSection-icon-1">
                                <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                            </div>
                            <div className="blogSection-icon-2">
                            <Link to="https://www.facebook.com/profile.php?id=61566326070506&mibextid=ZbWKwL" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></Link>
                                <Link to="https://www.linkedin.com/company/soplex-technologies/" className="btn me-1"><i className="fab fa-linkedin text-white"></i></Link>
                                <Link to="https://www.instagram.com/soplex_technology?igsh=dWFsbW04bm5yYjJ2" className="btn me-1"><i className="fab fa-instagram text-white"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="blog-content text-center position-relative px-3" style={{marginTop: '25px', height: '130px'}}>
                        <p className="py-2">The secret to achieving this lies in adopting a client-centric approach — one that prioritizes the client’s goals, satisfaction, and long-term success at every stage of a project.</p>
                    </div>
                    <div className="blog-coment d-flex justify-content-between px-4 py-3 border bg-primary rounded-bottom"></div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Blog End --> */}

{/* <!-- Contact Start --> */}
<div className="container-fluid py-5 my-5">
        {/* <!-- Parallax Backgrounds --> */}
<div className="parallax parallax-left"></div>
<div className="parallax parallax-right"></div>
    <div className="container pt-5">
        <div className="row g-5" style={{justifyContent: 'center', textAlign: 'center'}} >   
            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                <h5 className="text-primary">Get In Touch</h5>
                <h1 className="mb-4">Let's Transform Your Business!</h1>
                <p>Join us on the journey of digital transformation. Whether you’re looking to harness AI to streamline operations, develop custom software solutions, or integrate cutting-edge technologies, Soplex Technologies is here to help. Streamline your business operations with our custom AI tools and take the first step toward innovation and growth.</p>
                <p><strong>Partner with us and connect now to get started!</strong></p>
                <Link to="/contact" className="btn btn-secondary rounded-pill px-5 py-3 text-white">Contact US</Link>
            </div>
        </div>
    </div>
</div>
{/* <!-- Contact End --> */}
    </div>
  )
}
