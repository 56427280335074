import React, { useState, useEffect } from "react";

const Blog4 = () => {
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    fetch("Blogs/blog4.json")
      .then((response) => response.json())
      .then((data) => setBlogData(data))
      .catch((error) => console.error("Error fetching blog data:", error));
  }, []);

  if (!blogData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-container">
      <h1>{blogData.title}</h1>
      <p>{blogData.introduction}</p>

      {blogData.sections.map((section, index) => (
        <div className="section" key={index}>
          <h2>{section.title}</h2>
          <p>{section.content}</p>

          {section.subsections &&
            section.subsections.map((subsection, subIndex) => (
              <div className="subsection" key={subIndex}>
                <h3>{subsection.title}</h3>
                <p>{subsection.content}</p>
              </div>
            ))}
        </div>
      ))}

<div className="conclusion">
          <h2>Conclusion</h2>
      <p>{blogData.conclusion}</p>
    </div>
    </div>
  );
};

export default Blog4;
