import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Services() {
  useEffect(() => {
    // Set canonical link dynamically
    const canonicalLink = document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', 'https://soplextechnologies.com/services');
    document.head.appendChild(canonicalLink);

    // Set title dynamically
    document.title = 'Our Services - Smart Business Solutions | Soplex Technologies';

    // Set meta description dynamically
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content =
      'Discover the range of smart business solutions offered by Soplex Technologies, from software development to IT consulting. Transform your business with our expert services.';
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(canonicalLink);
      document.head.removeChild(metaDescription);
    };
  }, []);

  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch('/services.json') // path to the services.json file
      .then((response) => response.json())
      .then((data) => setServices(data))
      .catch((error) => console.error('Error fetching the services:', error));
  }, []);

  return (
    <div>
      {/* Services Start */}
      <div className="container-fluid services py-5 my-5">
        <div className="container py-5">
          <div className="text-center mx-auto pb-5" style={{ maxWidth: '820px' }}>
            <h5 className="text-primary">Our AI Services</h5>
            <h1 className="mb-4">Unlock the Power of Artificial Intelligence</h1>
            <p>
              AI is the future, and we’re here to help you unlock its full potential. Explore our cutting-edge AI services and discover how Soplex Technologies can transform your business.
            </p>
          </div>
          {/* AI Services Highlights */}
          <div className="row g-5 ai-services">
            <div className="col-md-6">
              <div className="ai-service-item">
                <i className="fa fa-brain fa-3x text-primary mb-3"></i>
                <h4>Custom AI Development Services</h4>
                <p>
                  We provide generative AI solutions tailored to your business needs. From data analysis to machine learning and predictive analytics, we create AI that drives smarter decisions and business growth.
                </p>
              </div>
              <div className="ai-service-item">
                <i className="fa fa-cogs fa-3x text-primary mb-3"></i>
                <h4>AI-Integrated Software Solutions</h4>
                <p>
                  Integrating AI into your systems can enhance efficiency without a complete overhaul. We deliver intelligent, cloud-based solutions for seamless integration.
                </p>
              </div>
              <div className="ai-service-item">
                <i className="fa fa-comments fa-3x text-primary mb-3"></i>
                <h4>AI-Powered Chatbots for Customer Support</h4>
                <p>
                  Enhance customer service with AI chatbots that provide instant responses, resolve issues, and offer personalized recommendations.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ai-service-item">
                <i className="fa fa-sync-alt fa-3x text-primary mb-3"></i>
                <h4>AI Optimization</h4>
                <p>
                  AI evolves, and so should your systems. We monitor and refine your AI technology to keep it effective, accurate, and adaptable to challenges.
                </p>
              </div>
              <div className="ai-service-item">
                <i className="fa fa-chart-line fa-3x text-primary mb-3"></i>
                <h4>Machine Learning Solutions</h4>
                <p>
                  Turn data into actionable insights. Our machine learning models help forecast trends, improve operations, and understand customer behavior.
                </p>
              </div>
              <div className="ai-service-item">
                <i className="fa fa-microphone fa-3x text-primary mb-3"></i>
                <h4>Natural Language Processing (NLP)</h4>
                <p>
                  We integrate NLP to enable smarter, human-like interactions in your applications, whether through text or speech.
                </p>
              </div>
            </div>
          </div>
          {/* software-services Highlights */}
          <div className="container-fluid software-services py-5">
            <div className="container py-5">
              <div className="text-center mx-auto pb-5" style={{ maxWidth: '850px' }}>
                <h5 className="text-primary">Software Development Services</h5>
                <h1 className="mb-4">Custom Solutions for Every Business</h1>
                <p>
                  At Soplex Technologies, we understand that every business is unique. That’s why we specialize in tailoring software solutions designed to solve your specific challenges and help you achieve your goals. Whether you need a responsive website, a powerful mobile app, or a complex enterprise system, our team has the expertise to deliver results.
                </p>
              </div>
              <div className="row g-5 software-services-list">
                <div className="col-md-6">
                  <div className="software-service-item">
                    <i className="fa fa-desktop fa-3x text-primary mb-3"></i>
                    <h4>Website Development Services</h4>
                    <p>
                      Your website is your digital storefront. We create websites that not only look great but also function seamlessly and provide a great user experience. From e-commerce platforms to content management systems, we build solutions that grow with your business.
                    </p>
                  </div>
                  <div className="software-service-item">
                    <i className="fa fa-shopping-cart fa-3x text-primary mb-3"></i>
                    <h4>E-Commerce Web Development</h4>
                    <p>
                      Build an online store that offers a smooth, secure, and engaging shopping experience. From payment processing to inventory management, we ensure your online business operates at its best.
                    </p>
                  </div>
                  <div className="software-service-item">
                    <i className="fa fa-mobile-alt fa-3x text-primary mb-3"></i>
                    <h4>Responsive Design</h4>
                    <p>
                      Your website should work on any device. We ensure it’s fully responsive, so it looks and performs flawlessly on desktops, tablets, and smartphones.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="software-service-item">
                    <i className="fa fa-code fa-3x text-primary mb-3"></i>
                    <h4>Custom Web Applications</h4>
                    <p>
                      If your business needs something more than a simple website, we specialize in developing custom web applications that support complex processes and improve business performance.
                    </p>
                  </div>
                  <div className="software-service-item">
                    <i className="fa fa-mobile fa-3x text-primary mb-3"></i>
                    <h4>Mobile App Development</h4>
                    <p>
                      A mobile app is often the easiest way to stay connected with your customers and employees. We create mobile apps for both iOS and Android that are fast, reliable, and user-friendly.
                    </p>
                  </div>
                  <div className="software-service-item">
                    <i className="fa fa-sync-alt fa-3x text-primary mb-5"></i>
                    <h4>Cross-Platform Compatibility</h4>
                    <p>
                      We build mobile apps that work on both iOS and Android, ensuring your business reaches a wide audience without the cost of developing separate apps for each platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Custom Software Solutions Highlights */}
          <div className="container-fluid custom-software py-5">
            <div className="container py-5">
              <div className="text-center mx-auto pb-5" style={{ maxWidth: '820px' }}>
                <h5 className="text-primary">Custom Software Solutions</h5>
                <h1 className="mb-4">Tailored Solutions for Your Unique Challenges</h1>
                <p>
                  Every business has its own set of challenges. Our affordable custom AI solutions for small businesses and premium software quality assurance and testing ensure that we provide you with the latest software tools, tailored to meet your needs.
                </p>
              </div>
              <div className="row g-5 custom-software-list">
                <div className="col-md-6">
                  <div className="custom-software-item">
                    <i className="fa fa-cogs fa-3x text-primary mb-3"></i>
                    <h4>Tailored Development</h4>
                    <p>
                      We develop custom software that is specifically designed to support your business processes and workflows, making sure you have the tools you need to succeed.
                    </p>
                  </div>
                  <div className="custom-software-item">
                    <i className="fa fa-link fa-3x text-primary mb-3"></i>
                    <h4>System Integration</h4>
                    <p>
                      Our solutions integrate with your existing infrastructure, ensuring smooth data flow and communication between systems, so you can work more efficiently.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="custom-software-item">
                    <i className="fa fa-code fa-3x text-primary mb-3"></i>
                    <h4>Frontend & Backend Development</h4>
                    <p>
                      Both the front end (what users see) and the back end (the engine behind the scenes) are essential to creating a great user experience. We provide both frontend and backend development services, ensuring your software is powerful and user-friendly.
                    </p>
                    <ul>
                      <li><strong>Frontend Development:</strong> We build intuitive and engaging user interfaces that are responsive, easy to navigate, and provide a seamless experience for your users.</li>
                      <li><strong>Backend Development:</strong> We design and develop scalable backend systems for growing businesses to ensure that your software is fast and secure.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ai-software-solutions */}
          <div className="container-fluid ai-software-solutions py-5">
            <div className="container py-5">
              <div className="text-center mx-auto pb-5" style={{ maxWidth: '820px' }}>
                <h5 className="text-primary">AI-Powered Software Solutions</h5>
                <h1 className="mb-4">Smarter, More Efficient Software</h1>
                <p>
                  Integrating AI into your software applications opens up new possibilities for automation, smarter decision-making, and personalized experiences. At Soplex Technologies, we specialize in AI-powered software solutions that make your systems more intelligent and effective.
                </p>
              </div>
              <div className="row g-5 ai-software-list">
                <div className="col-md-6">
                  <div className="ai-software-item">
                    <i className="fa fa-robot fa-3x text-primary mb-3"></i>
                    <h4>AI-Driven Automation</h4>
                    <p>
                      Automate repetitive tasks and processes with AI, freeing up time for your team to focus on higher-value activities.
                    </p>
                  </div>
                  <div className="ai-software-item">
                    <i className="fa fa-cogs fa-3x text-primary mb-3"></i>
                    <h4>Recommendation Systems</h4>
                    <p>
                      Personalize customer experiences by offering tailored recommendations based on data analysis, user behavior, and preferences.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ai-software-item">
                    <i className="fa fa-sitemap fa-3x text-primary mb-3"></i>
                    <h4>Integrated AI Platforms</h4>
                    <p>
                      Combine AI with your existing software to create an ecosystem that’s smarter and more efficient.
                    </p>
                  </div>
                  <div className="ai-software-item">
                    <i className="fa fa-line-chart fa-3x text-primary mb-3"></i>
                    <h4>Predictive Analytic Tools</h4>
                    <p>
                      Use AI to forecast trends, optimize supply chains, and improve customer service by predicting needs before they arise.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Cybersecurity Services */}
          <div className="container-fluid cybersecurity-services py-5">
            <div className="container py-5">
              <div className="text-center mx-auto pb-5" style={{ maxWidth: '820px' }}>
                <h5 className="text-primary">Cybersecurity Services</h5>
                <h1 className="mb-4">Protecting Your Business in a Digital World</h1>
                <p>
                  Cybersecurity services for businesses are now more critical than ever. With increasing cyber threats, it’s vital to ensure your business’s data, applications, and networks are protected. At Soplex Technologies, we provide robust cybersecurity services to safeguard your business from cyberattacks and data breaches.
                </p>
              </div>
              <div className="row g-5 cybersecurity-list">
                <div className="col-md-6">
                  <div className="cybersecurity-item">
                    <i className="fa fa-shield-alt fa-3x text-primary mb-3"></i>
                    <h4>AI-Based Threat Detection</h4>
                    <p>
                      Our AI-powered systems monitor your network and applications for unusual activity, identifying threats before they can do harm.
                    </p>
                  </div>
                  <div className="cybersecurity-item">
                    <i className="fa fa-lock fa-3x text-primary mb-3"></i>
                    <h4>End-to-End Security</h4>
                    <p>
                      From application security to network defense, we provide a comprehensive security approach that protects all areas of your digital infrastructure.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="cybersecurity-item">
                    <i className="fa fa-search fa-3x text-primary mb-3"></i>
                    <h4>Application Security Testing</h4>
                    <p>
                      We perform thorough security testing to identify vulnerabilities in your applications and ensure they meet industry standards.
                    </p>
                  </div>
                  <div className="cybersecurity-item">
                    <i className="fa fa-database fa-3x text-primary mb-3"></i>
                    <h4>Data Security Solutions</h4>
                    <p>
                      We provide data encryption and secure storage solutions to protect your business and customer data from unauthorized access.
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="cybersecurity-item col-12">
                    <i className="fa fa-check-circle fa-3x text-primary mb-3"></i>
                    <h4>Security Audits & Compliance</h4>
                    <p>
                      Our team conducts regular security audits to ensure your business is compliant with industry regulations and best practices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid transformation-section py-5">
  <div className="container py-5 position-relative">
    <div className="row justify-content-center text-center">
      <div className="col-lg-8">
        <h1 className="mb-4 fw-bold">Ready to Transform Your Business?</h1>
        <p className="mb-4 text-muted">
          Whether you're looking for AI-driven solutions, custom software, or cutting-edge cybersecurity, we’re here to help you succeed. 
        </p>
        <p className="text-muted mb-4">
          Let us guide you toward a smarter, more secure future. Your journey begins here.
        </p>
       
        <Link to="/contact" className="btn btn-primary px-5 py-3 rounded-pill">Let's Get Started</Link>
      </div>
    </div>
    
    <div className="left-shapess">
      <div className="trianglee"></div>
      <div className="small-circlee"></div>
    </div>
    
    <div className="right-shapess">
      <div className="rectanglee"></div>
      <div className="large-circlee"></div>
    </div>
  </div>
</div>


        </div>
      </div>
    </div>
  );
}
