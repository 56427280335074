import React, { useEffect } from 'react';


export default function Team() {
    useEffect(() => {
        const canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', 'https://soplextechnologies.com/team');
        document.head.appendChild(canonicalLink);

        document.title = 'Our Team - Meet the Experts | Soplex Technologies';

        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = 'Get to know the talented professionals behind Soplex Technologies. Our team of experts is dedicated to delivering smart solutions and driving business success.';
        document.head.appendChild(metaDescription);

        return () => {
            document.head.removeChild(canonicalLink);
            document.head.removeChild(metaDescription);
        };
    }, []);
    

    const teamMembers = [
        {
            name: 'Sajjad Ullah',
            role: 'Founder & CEO',
            description: 'A visionary leader and dynamic entrepreneur, Sajjad Ullah is the driving force behind Soplex Technologies. With expertise in AI, technology, and business strategy, he has dedicated himself to shaping the future of tech-driven solutions.',
            image: 'img/sajjad.jpeg'
        },
        {
            name: 'Eeman Khalid',
            role: 'Co-Founder & CTO',
            description: 'A trailblazing young entrepreneur and technology expert, Eman Khalid leads the innovation and technical strategy at Soplex Technologies. Her expertise in advanced technologies and leadership sets her apart as a pivotal force in our team.',
            image: 'img/eeman.jpeg'
        },
        {
            name: 'Mr. Obaid Ur Rehman',
            role: 'Chief Strategic Advisor',
            description: 'With decades of experience in IT and technology, Mr. Obaid Ur Rehman is the backbone of our technical expertise. As a mentor and guide, his wisdom and insights fuel the growth of our team and the quality of our services.',
            image: 'img/obaid.jpeg'
        },
        {
            name: 'Izhar Malik',
            role: 'Chief Operating Officer (COO)',
            description: 'A seasoned professional and strategic thinker, Izhar Malik drives operational excellence at Soplex Technologies. His focus on efficiency, scalability, and team coordination ensures that every project is executed with precision and success.',
            image: 'img/izhar.jpeg'
        }
    ];

    return (
        <div className="team-page">
            <div className="container text-center">
                <h1>Meet Our Team</h1>
                <p className="intro-text">
                    At Soplex Technologies, our team is the driving force behind our innovation and success. Each member brings unparalleled expertise and passion to create groundbreaking solutions in the world of technology.
                </p>
            </div>
            <div className="team-members container">
                {teamMembers.map((member, index) => (
                    <div className="team-member" key={index}>
                        <div className="text-section">
                            <h2>{member.name}</h2>
                            <h4>{member.role}</h4>
                            <p>{member.description}</p>
                        </div>
                        <div className="image-section">
                            <img src={member.image} alt={member.name} className="team-image" />
                        </div>
                    </div>
                ))}
            </div>
            
        </div>
    );
}
